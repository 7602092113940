
  import { Action, Getter } from "vuex-class";
  import { Component, Vue } from "vue-property-decorator";
  import type { indexTimelinePayload } from "@/store/modules/timeline.store";
  import { Seller } from "@/store/modules/seller.store";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
      ActivityHeaderPart: () => import("@/views/seller/parts/activity/activity-header.vue"),
      ContactInfoPart: () => import("@/views/seller/parts/contact/contact-info.vue"),
      TimelineEntryCard: () => import("@/components/seller/timeline/entry-card.vue"),
      TimelineCreateEntry: () => import("@/components/seller/timeline/create-entry.vue"),
      SharedSellers: () => import("@/views/seller/parts/shared-sellers.vue"),
      PageTitle: () => import("./parts/general/page-title.vue"),
    },
  })
  export default class PageSellerTimeline extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("timeline/all") timelineItems!: TimelineItem[];
    @Getter("activity/viewing") activity!: Activity;
    @Getter("seller/all") sellers!: Seller[];

    @Action("timeline/index") indexTimeline!: (payload: indexTimelinePayload) => Promise<TimelineItem[]>;

    loading = false;

    async mounted() {
      let sellerId: boolean | number = false;
      if (!this.me.seller_id) {
        sellerId = this.sellers[0].id;
      }

      this.loading = true;

      await this.indexTimeline({ q: { and: { activity_id: Number(this.$route.params.id) } }, s: { created_at: "desc" }, per_page: 999, seller_id: sellerId });

      this.loading = false;
    }
  }
